import { env } from '../utils/env'
import { DEFAULT_TENANT } from './bootstrap'
import { SPA_URL } from './global'

class Idh {
  BASEURL: string | undefined
  GRANT_TYPE: string
  METHOD: string
  EXPECTED_SCOPE: string
  CALLBACK_URL: string
  EXPECTED_CLIENT_ID: string
  ENDPOINTS: {
    AUTHORIZE: string
    DISCOVERY: string
    FORGOTPASSWORD: string
    REQUESTELIGIBILITY: string
    TOKEN: string
    JSONWEBKEYSET: string
    ENDSESSION: string
  }
  constructor(tenant = DEFAULT_TENANT) {
    this.BASEURL = env('REACT_APP_IDH_BASE_URL') + `/${tenant}`
    this.GRANT_TYPE = 'authorization_code'
    this.METHOD = 'S256'
    this.EXPECTED_SCOPE = 'openid profile email role'
    this.CALLBACK_URL = `${SPA_URL}/signin/code`
    this.EXPECTED_CLIENT_ID = 'ESTORE_SPA'
    this.ENDPOINTS = {
      AUTHORIZE: `${this.BASEURL}/connect/authorize`,
      TOKEN: `${this.BASEURL}/connect/token`,
      DISCOVERY: `${this.BASEURL}/.well-known/openid-configuration/`,
      FORGOTPASSWORD: `${this.BASEURL}/Onboarding/ChangePassword`,
      REQUESTELIGIBILITY: `${this.BASEURL}/Onboarding/RequestEligibility`,
      JSONWEBKEYSET: `${this.BASEURL}/.well-known/openid-configuration/jwks`,
      ENDSESSION: `${this.BASEURL}/connect/endsession`,
    }
  }
  public getAcrString(isGuest: boolean, isCheckout: boolean, tenant = DEFAULT_TENANT) {
    return `tenant:${tenant} guest:${isGuest ? 1 : 0} checkout:${isCheckout ? 1 : 0}`
  }
}
export const IDH = new Idh()
