import localforage from 'localforage'

export const resCache = localforage.createInstance({
  name: 'response-cache',
})

const defaultStoreName = 'defaultStore'

const defaultStore = localforage.createInstance({
  name: defaultStoreName,
})

const userDataStoreName = 'userDataStore'
export const userDataStore = localforage.createInstance({
  name: defaultStoreName,
  storeName: userDataStoreName,
})

export const cacheBusters = async (): Promise<void> => {
  await localforage.clear()
  await defaultStore.clear()
  await userDataStore.clear()
  await resCache.clear()
  if (window.indexedDB) {
    await localforage.dropInstance({ name: 'localforage' })
    await localforage.dropInstance({ name: defaultStoreName })
    await localforage.dropInstance({ name: defaultStoreName, storeName: userDataStoreName })
    await localforage.dropInstance({ name: 'resCache' })
  }

  return
}

export const clearResCache = async (): Promise<void> => {
  await resCache.clear()
}

class CachedItem {
  keyName: string
  backingCache: LocalForage
  constructor(keyName: string, backingCache = defaultStore) {
    this.keyName = keyName
    this.backingCache = backingCache
  }

  public async get<T>(): Promise<T | undefined> {
    const fromCacheValue = await this.backingCache.getItem(this.keyName)
    return fromCacheValue ? (fromCacheValue as T) : undefined
  }

  public async set<T>(toCacheValue?: T): Promise<void> {
    if (toCacheValue) await this.backingCache.setItem(this.keyName, toCacheValue)
  }

  public async clear(): Promise<void> {
    await this.backingCache.removeItem(this.keyName)
  }
}
export const accessTokenCache = new CachedItem('accessToken')
export const tokenResponseCache = new CachedItem('tokenResponse')
export const PKCECache = new CachedItem('codeVerifier')
export const loginHintCache = new CachedItem('loginHint')
export const originPathCache = new CachedItem('originPath')
export const apiCacheInvalidationFlagCache = new CachedItem('apiCacheInvalidationFlag')
export const buildHashCache = new CachedItem('buildHash')
export const shoppingInstanceIdCache = new CachedItem('shoppingInstanceId')
export const appSettingsCache = new CachedItem('appSettings')
