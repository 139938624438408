/* eslint-disable @typescript-eslint/no-explicit-any */

import { USER_PROFILE_URL } from '../../constants'
import { api, getHeaders, accessTokenCache, handleUnauthorizedRequest } from '../../services'
import { User, UserEligibility, UserProfileFormData } from '../../types'

type GetUserService = () => Promise<GetUserRes | undefined>
type UpdateUserRes = {
  data: {
    shoppingInstanceId: string
    message: string
    statusCode: number
    data: User
  }
}

export type UpdateUserService = (params: UserProfileFormData) => Promise<User | undefined>

export type GetUserRes = {
  user: User
  verifications: UserEligibility[]
}

export const getUser: GetUserService = async () => {
  try {
    const token = await accessTokenCache.get<string>()
    if (!token) throw Error('must be logged in')
    const headers = await getHeaders()

    const res = await api.get(USER_PROFILE_URL, {
      headers,
    })
    const data = res.data.data

    return data
  } catch (e: any) {
    if (e.response && e.response.status === 401) {
      await handleUnauthorizedRequest()
    } else throw e
  }
}

export const updateUser: UpdateUserService = async (params: UserProfileFormData) => {
  try {
    const token = await accessTokenCache.get<string>()
    if (!token) throw Error('must be logged in')
    const headers = await getHeaders()
    const res: UpdateUserRes = await api.put(USER_PROFILE_URL, params, {
      headers,
    })

    const data = res.data.data

    return data
  } catch (e) {
    return undefined
  }
}
