/* eslint-disable @typescript-eslint/ban-ts-comment */

import axios from 'axios'
import { t } from 'i18next'
import jwt from 'jsonwebtoken'
import jwkToPem from 'jwk-to-pem'
import getPkce from 'oauth-pkce'

import { openSnackbar } from '../components/snackbar'
import { DEFAULT_POPUP_DURATION, IDH, SPA_URL } from '../constants'
import { TokenSet, TokenPayload, PKCE } from '../types'
import { tokenResponseCache, loginHintCache, originPathCache, accessTokenCache, PKCECache } from './cache'

export const idhApi = axios.create({
  baseURL: IDH.BASEURL,
})

const isIe = !!window.document.documentMode

const storePKCEState = async (PKCEValues: PKCE) => {
  await PKCECache.set(PKCEValues)
}

export const dec2hex = (dec: number): string => (0 + dec.toString(16)).substr(-2)

export const generateRandomString = (): string =>
  // @ts-ignore
  Array.from(window[isIe ? 'msCrypto' : 'crypto'].getRandomValues(new Uint8Array(28)), dec2hex).join('')

export const buildAuthorizeUrl = (
  state: string,
  challenge: string,
  acr: string,
  nonce: string,
  loginHint: string | undefined = undefined
): string =>
  `${IDH.ENDPOINTS.AUTHORIZE}` +
  `?client_id=${encodeURIComponent(IDH.EXPECTED_CLIENT_ID)}` +
  `&response_type=code` +
  `${loginHint ? `&login_hint=${loginHint as string}` : ''}` +
  `&redirect_uri=${encodeURIComponent(IDH.CALLBACK_URL)}` +
  `&state=${encodeURIComponent(state)}` +
  `&scope=${encodeURIComponent(IDH.EXPECTED_SCOPE)}` +
  `&code_challenge=${encodeURIComponent(challenge)}` +
  `&code_challenge_method=${encodeURIComponent(IDH.METHOD)}` +
  `&acr_values=${encodeURIComponent(acr)}` +
  `&nonce=${encodeURIComponent(nonce)}`

export const handleAuth = async (guest = false, checkout = false, originPath = window.location.pathname): Promise<void> => {
  const { verifier, challenge } = await new Promise<{ verifier: string; challenge: string }>((resolve) => {
    getPkce(43, (error, { verifier, challenge }) => {
      if (error) throw error
      resolve({ verifier: verifier, challenge: challenge })
    })
  })
  const state = generateRandomString()
  const nonce = generateRandomString()
  const acr = IDH.getAcrString(guest, checkout)
  const loginHint = await loginHintCache.get<string>()

  await storePKCEState({ verifier: verifier, state: state })
  const authRequestUrl = buildAuthorizeUrl(state, challenge, acr, nonce, loginHint)
  performIDHRedirectWithRouteRecovery(originPath, authRequestUrl)
}

export const handleUnauthorizedRequest = async () => {
  await clearOAuthState()
  await handleAuth()
}

export const handleUnexpectedAuthError = async () => {
  openSnackbar(t('Error_globalErrorMessage'), DEFAULT_POPUP_DURATION, 'topLeft', 'failure')
  await clearOAuthState()
}

export const handlePasswordChange = async (originPath: string): Promise<void> => {
  const changePasswordUrl = `${IDH.ENDPOINTS.FORGOTPASSWORD}?returnUrl=${encodeURIComponent(SPA_URL + originPath)}`
  performIDHRedirectWithRouteRecovery(originPath, changePasswordUrl)
}

export const handleRequestAdditionalEligibilities = async (originPath: string): Promise<void> => {
  const requestEligibilityUrl = `${IDH.ENDPOINTS.REQUESTELIGIBILITY}?returnUrl=${encodeURIComponent(SPA_URL + originPath)}`
  performIDHRedirectWithRouteRecovery(originPath, requestEligibilityUrl)
}

export const getFormData = (obj: { [key: string]: string }): FormData =>
  Object.keys(obj).reduce((formData, key) => {
    formData.append(key, obj[key])
    return formData
  }, new URLSearchParams())

export const clearOAuthState = async (): Promise<void> => {
  await PKCECache.clear()
  await tokenResponseCache.clear()
  await accessTokenCache.clear()
  await loginHintCache.clear()
}

const performIDHRedirectWithRouteRecovery = async (landingRoute: string, idhRoute: string): Promise<void> => {
  await originPathCache.set(landingRoute)
  window.history.replaceState(null, landingRoute, SPA_URL + landingRoute)
  window.location.href = idhRoute
}

const performTokenRequest = async (authCode: string, verifier: string): Promise<TokenPayload | undefined> => {
  try {
    const tokenRequestBody = {
      grant_type: IDH.GRANT_TYPE,
      client_id: IDH.EXPECTED_CLIENT_ID,
      code_verifier: verifier,
      redirect_uri: `${SPA_URL}/signin/code`,
      code: authCode,
    }

    const { data } = await idhApi.post(IDH.ENDPOINTS.TOKEN, getFormData(tokenRequestBody), {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })

    return data
  } catch (e: unknown) {
    await clearOAuthState()
    await handleAuth()
  }
}

const verifyToken = async (token: string): Promise<string | jwt.JwtPayload> => {
  const getPublicKey = async () => {
    const { data } = await idhApi.get(IDH.ENDPOINTS.JSONWEBKEYSET)
    const [jwk] = data.keys
    return jwkToPem(jwk)
  }

  const pk = await getPublicKey()
  const payload = jwt.verify(token, pk)

  if (!payload) throw Error('Invalid Token')
  return payload
}

export const getToken: (authCode: string, stateParam: string) => Promise<string | undefined> = async (
  authCode: string,
  stateParam: string
) => {
  const PKCEValues = await PKCECache.get<PKCE>()
  const verifier = PKCEValues?.verifier
  const isExpectingAuthRequest = authCode && stateParam && verifier && PKCEValues && PKCEValues.state === stateParam

  if (!isExpectingAuthRequest) {
    handleAuth()
    return
  }

  const data = await performTokenRequest(authCode, verifier)
  const tokenSet = new TokenSet(data)
  const { sub } = await verifyToken(tokenSet.idToken)

  await tokenResponseCache.set(tokenSet)
  await accessTokenCache.set(tokenSet.accessToken)
  await loginHintCache.set(sub)

  const accessToken = await accessTokenCache.get<string>()
  if (!accessToken) await handleUnauthorizedRequest()

  return accessToken
}

export const endSession = async (): Promise<void> => {
  const tokenSet = await tokenResponseCache.get<TokenSet>()
  await clearOAuthState().then(
    () =>
      (window.location.href =
        IDH.ENDPOINTS.ENDSESSION + `?post_logout_redirect_uri=${encodeURIComponent(SPA_URL + '/')}&id_token_hint=${tokenSet?.idToken}`)
  )
}
